<template>
  <div class="bordered">
    <Title>诊疗服务</Title>
    <div class="m_xnorm" v-if="isData">
      <div class="rows">
        <div class="m_xare">
          <div class="our">
            <b>诊疗人次增长率</b>
            <div class="us"><countTo :startVal="0" :endVal="treatmentServicesObj.personVisitRate" :duration="CountAnimationDuration"></countTo>%</div>
          </div>
          <div class="bg"><i class="blue" :style="{transition: `all ${CountAnimationDuration / 2}ms ease-in-out`, width: treatmentServicesObj.personVisitRate + '%'}"><dfn></dfn></i></div>
        </div>
      </div>
      <div class="rows">
        <div class="m_xare">
          <div class="our">
            <b>中医诊疗人次占比</b>
            <div class="us"><countTo :startVal="0" :endVal="treatmentServicesObj.tcmPersonVisitRate" :duration="CountAnimationDuration"></countTo>%</div>
          </div>
          <div class="bg"><i class="blue"  :style="{transition: `all ${CountAnimationDuration / 2}ms ease-in-out`, width: treatmentServicesObj.tcmPersonVisitRate + '%'}"><dfn></dfn></i></div>
        </div>
      </div>
      <div class="rows">
        <div class="m_xare">
          <div class="our">
            <b>病床使用率</b>
            <div class="us"><countTo :startVal="0" :endVal="treatmentServicesObj.bedsUtilizationRate" :duration="CountAnimationDuration"></countTo>%</div>
          </div>
          <div class="bg"><i class="blue" :style="{transition: `all ${CountAnimationDuration / 2}ms ease-in-out`, width: treatmentServicesObj.bedsUtilizationRate + '%'}"><dfn></dfn></i></div>
        </div>
      </div>
    </div>
    <div class="m_xnodata" v-else>
      <img src="@/assets/imgs/noData.png" alt="" />
      <em>暂无数据</em>
    </div>
  </div>
</template>
<script>
import Title from '../components/Title.vue'
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
  components: {Title, countTo},
  data() {
    return {
      CountAnimationDuration,
      isData: false
    }
  },
  props:['treatmentServicesObj'],
  mounted() {
  },
  watch:{
    treatmentServicesObj:{
      handler(val){
        let length = Number(val.personVisitRate)+Number(val.tcmPersonVisitRate)+Number(val.bedsUtilizationRate)
        if(length == 0){
          this.isData = false
        }else{
          this.isData = true
        }
      },
      deep:true
    }
  }
}
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.m_xnorm{
  padding:0 18px;
  .rows{
    overflow: hidden;
    margin: 24px 0;
    background: #0A2C5F;
    padding: 22px 22px 30px;
  }
}
.m_xare .our{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color:#fff;
  b{
    font-weight: normal;
    position: relative;
    padding-left: 18px;
  }
  b::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #98EFF8;
    left:0;
    top:50%;
    margin-top: -5px;
  }
  .us{
    color:#4AAEFF;
    font-size: 20px;
  }
}
.m_xare .bg{
  background: #16356F;
  margin-top: 12px;
  i{
    display: block;
    height: 14px;
    background: linear-gradient(93deg, #02ADC0 0%, #50AD8C 100%);
    opacity: 0.89;
    position: relative;
  }
  .blue{
    background: linear-gradient(93deg, #72E4F3 0%, #4AAEFF 100%);
  }
  dfn{
    position: absolute;
    height: 14px;
    background: url('../assets/wire.png') repeat-x 0 0;
    background-size: auto 100%;
    width: 100%;
    left: 0;
    top:0;
  }
}
.m_xnodata{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  flex-direction: column;
  height: 408px;
  em{
    font-style: normal;
  }
}
</style>