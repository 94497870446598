<template >
  <div class="chartDom" ref="chartDom"></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  props: ['option'],
  data() {
    return {
    }
  },
  mounted() {
    this.initChart()
  },
  methods:{
    initChart(){
      if(this.chart){
        this.chart.dispose()
      }
      this.chart = echarts.init(this.$el);
      if(this.option){
        this.chart.setOption(this.option)
      }
    }
  },
  watch: {
    option: {
      handler(){
        this.initChart()
      },
      deep: true
    }
  },
  beforeDestroy() {
    if(!this.chart){
      return
    }
    this.chart.dispose()
    this.chart = null
  }
}
</script>
<style lang="scss" scoped>
.echart-container {
  width: 100%;
  height: 100%;
  .chartDom {
    width: 100%;
    height: 100%;
  }
}
</style>