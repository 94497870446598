<template>
  <div class="bordered mt16">
    <Title>基础指标</Title>
    <div class="m_xares" v-if="isData">
      <div class="line" v-for="(v, i) in essenArr" :key="i">
        <img src="../assets/crew.png" class="crew">
        <p>{{ v.name }}</p>
        <div class="value">
          <countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo>
        </div>
      </div>
    </div>
    <div class="m_xnodata" v-else>
      <img src="@/assets/imgs/noData.png" alt="" />
      <em>暂无数据</em>
    </div>
  </div>
</template>
<script>
import Title from '../components/Title.vue';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
  components: { Title, countTo },
  data() {
    return {
      CountAnimationDuration,
      essenArr: [{ value: 0, name: '诊疗人次数' }, { value: 0, name: '门诊中医非药物疗法诊疗人次' }, { value: 0, name: '医师日均担负诊疗人次' }, { value: 0, name: '出院人数' }],
      option: null,
      isData: false
    };
  },
  props: ['basicIndicators'],
  watch: {
    basicIndicators: {
      handler(val) {
        this.essenArr.forEach((item, index) => {
          if (index == 0) {
            item.value = val.personVisitNumber
          }else if(index == 1) {
            item.value = val.tcmNonDrugOutpatientNumber
          }else if(index == 2) {
            item.value = val.visitsAverageNumber
          }else if(index == 3) {
            item.value = val.dischargedPatientNumber
          }
        })
        let length = Number(val.personVisitNumber)+Number(val.tcmNonDrugOutpatientNumber)+Number(val.visitsAverageNumber)+Number(val.dischargedPatientNumber)
        if(length == 0){
          this.isData = false
        }else{
          this.isData = true
        }
      },
      deep: true
    }
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';

.bordered {
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}

.m_xares {
  padding: 0 5px 15px;
  text-align: center;

  .line {
    width: 47%;
    height: 194px;
    background: rgba(23, 116, 255, 0.25);
    margin: 15px 1.5% 0;
    display: inline-block;
  }

  p {
    font-size: 14px;
    color: #fff;
    padding: 4px 0;
  }
  .crew {
    display: inline-block;
    width: 76px;
    height: 76px;
    margin: 30px 0 10px;
  }
}
.value {
  color: #00F7F9;
  font-size: 22px;
}
.m_xnodata{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  flex-direction: column;
  height: 434px;
  em{
    font-style: normal;
  }
}
</style>