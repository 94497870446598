<template>
  <div class="bordered">
    <Title>服务指标</Title>
    <div class="m_xnorm" v-if="isData">
      <div class="rows">
        <div class="m_xare">
          <div class="our">
            <b>65岁及以上老年人城乡社区规范健康管理服务(率)</b>
            <div class="us"><countTo :startVal="0" :endVal="serviceIndicators.sixtyfiveElderHealthServiceRate" :duration="CountAnimationDuration" :decimals="2"></countTo>%</div>
          </div>
          <div class="bg"><i :style="{transition: `all ${CountAnimationDuration / 2}ms ease-in-out`, width: serviceIndicators.sixtyfiveElderHealthServiceRate + '%'}"><dfn></dfn></i></div>
        </div>
      </div>
      <div class="rows">
        <div class="m_xare">
          <div class="our">
            <b>高血压患者基层规范管理服务率</b>
            <div class="us"><countTo :startVal="0" :endVal="serviceIndicators.hypertensionHealthServiceRate" :duration="CountAnimationDuration" :decimals="2"></countTo>%</div>
          </div>
          <div class="bg"><i :style="{transition: `all ${CountAnimationDuration / 2}ms ease-in-out`,width: serviceIndicators.hypertensionHealthServiceRate + '%'}"><dfn></dfn></i></div>
        </div>
      </div>
      <div class="rows">
        <div class="m_xare">
          <div class="our">
            <b>2型糖尿病患者基层规范管理服务率</b>
            <div class="us"><countTo :startVal="0" :endVal="serviceIndicators.diabetesHealthServiceRate" :duration="CountAnimationDuration" :decimals="2"></countTo>%</div>
          </div>
          <div class="bg"><i :style="{transition: `all ${CountAnimationDuration / 2}ms ease-in-out`,width: serviceIndicators.diabetesHealthServiceRate + '%'}"><dfn></dfn></i></div>
        </div>
      </div>
    </div>
    <div class="m_xnodata" v-else>
      <img src="@/assets/imgs/noData.png" alt="" />
      <em>暂无数据</em>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Title from '../components/Title.vue'
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
  components: {Title, countTo},
  data() {
    return {
      CountAnimationDuration,
      isData: false
    }
  },
  props:['serviceIndicators'],
  watch: {
    serviceIndicators: {
      handler(val) {
        let length = Number(val.sixtyfiveElderHealthServiceRate) + Number(val.hypertensionHealthServiceRate) + Number(val.diabetesHealthServiceRate)
        if (length == 0) {
          this.isData = false
        } else {
          this.isData = true
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.m_xnorm{
  padding:0 18px;
  .rows{
    overflow: hidden;
    margin: 24px 0;
    background: #0A2C5F;
    padding: 22px 22px 30px;
  }
}
.m_xare .our{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color:#fff;
  b{
    font-weight: normal;
    position: relative;
    padding-left: 18px;
  }
  b::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #A9F9EB;
    left:0;
    top:50%;
    margin-top: -5px;
  }
  .us{
    color:#4CD9F7;
    font-size: 20px;
  }
}
.m_xare .bg{
  background: #16356F;
  margin-top: 12px;
  i{
    display: block;
    height: 14px;
    background: linear-gradient(93deg, #84F5DE 0%, #56E8F2 50%, #4CC5F8 100%);
    opacity: 0.89;
    position: relative;
  }
  dfn{
    position: absolute;
    height: 14px;
    background: url('../assets/wire.png') repeat-x 0 0;
    background-size: auto 100%;
    width: 100%;
    left: 0;
    top:0;
  }
}
.m_xnodata{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  flex-direction: column;
  height: 408px;
  em{
    font-style: normal;
  }
}
</style>