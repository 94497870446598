<template lang="">
<div>
  <el-row class="page" style="margin: 0" :gutter="16">
    <el-col :span="6">
			<district @healthChange="healthChange"></district>
		</el-col>
    <el-col :span="6">
			<LeftTop :indicatorsObj='indicatorsObj'></LeftTop>
			<LeftCenter :manageIndicators='manageIndicators'></LeftCenter>
		</el-col>
    <el-col :span="6">
			<centerTop :treatmentServicesObj='treatmentServicesObj'></centerTop>
			<RightBottom :basicIndicators='basicIndicators'></RightBottom>
		</el-col>
    <el-col :span="6">
			<RightTop :serviceIndicators='serviceIndicators'></RightTop>
			<CenterBottom></CenterBottom>
		</el-col>
	</el-row>
	<el-date-picker v-model="valueYear" type="year" placeholder="选择年" class="timeRange2" value-format="yyyy-MM-dd HH:mm:ss" @change='timeselect'></el-date-picker>
  </div>
</template>
<script>
import LeftTop from './LeftTop.vue';
import LeftCenter from './LeftCenter.vue';
import centerTop from './centerTop.vue';
import CenterBottom from './CenterBottom.vue';
import RightTop from './RightTop.vue';
import RightBottom from './RightBottom.vue';
import district from './district.vue';
export default {
	components: { LeftTop, LeftCenter, CenterBottom, RightTop, RightBottom, centerTop, district },
	data() {
		return {
			indicatorsObj: {
				operationalPersonnelExpenditureRate: 0,
				medicalServiceIncomeRate: 0,
				employeeAverageCurrentSurplusPer: 0
			},
			treatmentServicesObj: {
				personVisitRate: 0,
				tcmPersonVisitRate: 0,
				bedsUtilizationRate: 0
			},
			serviceIndicators: {
				sixtyfiveElderHealthServiceRate: 0,
				hypertensionHealthServiceRate: 0,
				diabetesHealthServiceRate: 0
			},
			manageIndicators: {
				diabetesQualifiedRate: 0,
				psychosisHealthServiceRate: 0,
				familyDoctorContractRate: 0,
				hypertensionQualifiedRate: 0,
			},
			basicIndicators: {
				personVisitNumber: 0,
				tcmNonDrugOutpatientNumber: 0,
				visitsAverageNumber: 0,
				dischargedPatientNumber: 0
			},
			valueYear: '2023-01-01 00:00:00',
			Id: ''
		}
	},
	mounted() {
	},
	methods: {
		getList(id) {
			this.$http.get('/management-api/v1/PerformanceEvaluationResults.json', { params: { 'medicalOrganizationId': id, 'reportYear': this.valueYear, enabled: true } }).then((res) => {
				let data = res.data.collection[0]
				if(data){
					this.indicatorsObj = {
					operationalPersonnelExpenditureRate: data.operationalPersonnelExpenditureRate,
					medicalServiceIncomeRate: data.medicalServiceIncomeRate,
					employeeAverageCurrentSurplusPer: data.employeeAverageCurrentSurplusPer
				}
				this.treatmentServicesObj = {
					personVisitRate: data.personVisitRate,
					tcmPersonVisitRate: data.tcmPersonVisitRate,
					bedsUtilizationRate: data.bedsUtilizationRate
				}
				this.serviceIndicators = {
					sixtyfiveElderHealthServiceRate: data.sixtyfiveElderHealthServiceRate,
					hypertensionHealthServiceRate: data.hypertensionHealthServiceRate,
					diabetesHealthServiceRate: data.diabetesHealthServiceRate
				}
				this.manageIndicators = {
					diabetesQualifiedRate: data.diabetesQualifiedRate,
					psychosisHealthServiceRate: data.psychosisHealthServiceRate,
					familyDoctorContractRate: data.familyDoctorContractRate,
					hypertensionQualifiedRate: data.hypertensionQualifiedRate,
				}
				this.basicIndicators = {
					personVisitNumber: data.personVisitNumber,
					tcmNonDrugOutpatientNumber: data.tcmNonDrugOutpatientNumber,
					visitsAverageNumber: data.visitsAverageNumber,
					dischargedPatientNumber: data.dischargedPatientNumber
				}
				}else{
					this.clear()
				}
			})
		},
		healthChange(val) {
			if (val) {
				this.id = val.id
				this.getList(val.id)
			} else {
				this.clear()
			}

		},
		timeselect() {
			this.getList(this.id)
		},
		clear(){
			this.indicatorsObj = {
					operationalPersonnelExpenditureRate: 0,
					medicalServiceIncomeRate: 0,
					employeeAverageCurrentSurplusPer: 0
				},
					this.treatmentServicesObj = {
						personVisitRate: 0,
						tcmPersonVisitRate: 0,
						bedsUtilizationRate: 0
					},
					this.serviceIndicators = {
						sixtyfiveElderHealthServiceRate: 0,
						hypertensionHealthServiceRate: 0,
						diabetesHealthServiceRate: 0
					},
					this.manageIndicators = {
						diabetesQualifiedRate: 0,
						psychosisHealthServiceRate: 0,
						familyDoctorContractRate: 0,
						hypertensionQualifiedRate: 0,
					},
					this.basicIndicators = {
						personVisitNumber: 0,
						tcmNonDrugOutpatientNumber: 0,
						visitsAverageNumber: 0,
						dischargedPatientNumber: 0
					}
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/ .timeRange2 {
	position: absolute;
	right: 30px;
	top: 20px;
	width: 160px;
	padding-right: 26px;
	align-items: center;
	justify-content: space-between;
	font-size: 17px;
	font-weight: bold;
	height: 42px;
	border: none;
	color: white;
	background-image: url("../assets/timeRangeBg.jpg");
	background-repeat: no-repeat;
	background-size: 100% 100%;

	.el-input--prefix .el-input__inner {
		background-color: transparent;
		border: none;
	}

	.el-input__icon {
		display: none;
	}

	.el-range-separator {
		display: flex;
		align-items: center;
		color: white;
		margin-bottom: 1px;
		width: 18px;
	}

	.el-range-input {
		color: white;
		font-weight: bold;
		font-size: 17px;
		background-color: transparent;
	}

	.el-input__inner {
		background: none;
		border: none;
		height: 42px;
		line-height: 42px;
		width: 160px;
		padding: 0;
		color: #fff;
		text-align: center;
	}
}
</style>